.jqvmap-zoomin,
.jqvmap-zoomout {
    height: 20px;
    width: 20px;
    line-height: 14px;
    background-color: $primary;
    color: $white;
}

.jqvmap-zoomout {
    top: 35px;
}

#world-map, #usa {
    height: 400px;
	@include respond ('tab-port'){
		height:350px;
	}
	@include respond ('phone'){
		height:230px;
	}
}


#world-map {
    height: 400px;
    svg{
        path {
            fill: #eff2f4;
            stroke: #fff;
        }
    } 
}