


.footer {
    padding-left: 17.1875rem;
    background-color:$white;
    .copyright {
		background-color: $body-bg;
        padding: 0.9375rem;
        p {
            text-align: center;
            margin: 0;
			font-size: 14px;
        }

        a{
            color: $primary;
        }
    }
}


