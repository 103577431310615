//customize bootstrap
@import './bs-custom';

$strong:#646c9a;
$border: #EEEEEE;
$shadow: 0px 0px 40px 0px rgba(82,63,105,0.1);
$body-bg: #F9F9F9;


///////////////
// Solid Color
///////////////
$white: #fff;
$black: #000;
$charade: #23252F;

/////////////////////////
// Dark Version Color
/////////////////////////
$d-ctd: #ddd;
$d-ctl: #828690;
$d-border: #636363;
$d-bg: #24292D;
$dark-card: #2F363E;
$dark_bg_lighter: #1E2A4A;

/////////////////////////
// Primary Light
/////////////////////////
$primary-light: lighten($primary, 38%);
$secondary-light: lighten($secondary, 40%);
$success-light: lighten($success, 45%);
$warning-light: lighten($warning, 34%);
$danger-light: lighten($danger, 34%);
$info-light: lighten($info, 34%);
$dark-light: lighten($dark, 50%);

/////////////////////////
// Opacity 
/////////////////////////
$primary-opacity: rgba($primary, 0.1);
$secondary-opacity: rgba($secondary, 0.5);
$success-opacity: rgba($success, 0.1);
$warning-opacity: rgba($warning, 0.1);
$danger-opacity: rgba($danger, 0.1);
$info-opacity: rgba($info, 0.1);
$dark-opacity: rgba($dark, 0.35);

////////////////////////
// Light Version Color
////////////////////////
$l-ctd: #464a53;
$l-ctl: #828690;
$l-border: #eaeaea;
$l-bg: #f2f4fa;
$heading: #333;

///////////////////
// Material Colors
///////////////////
$dusty-gray: #999999;
$gallery: #EEEEEE;
$gray: #898989;
$input-border-color: $border;


///////////////////
// Social Colors
///////////////////
$facebook: #3b5998;
$twitter: #1da1f2;
$youtube: #FF0000;
$google-plus: #db4439;
$linkedin: #007bb6;
$instagram: #c32aa3;
$pinterest: #bd081c;
$google: #4285f4;
$snapchat: #fffc00;
$whatsapp: #25d366;
$tumblr: #35465d;
$reddit: #ff4500;
$spotify: #1ed760;
$yahoo: #430297;
$dribbble: #ea4c89;
$skype: #00aff0;
$quora: #aa2200;
$riverBed: #4C585C;
$vimeo: #1ab7ea;


///////////////////
// Misc Colors
///////////////////
$iron: #DDDFE1;
$grey: #D2D6DE;
$pale-sky: #6a707e;
$athensGray: #F0F0F2;
$sky: #2290FF;



//color for coller pallate
/* $color_pallate_1: $white;
$color_pallate_2: #143b64;
$color_pallate_3: $primary;
$color_pallate_4: #4527a0;
$color_pallate_5: #ff4146;
$color_pallate_6: #283593;
$color_pallate_7: #7356f1;
$color_pallate_8: #3695eb;
$color_pallate_9: #00838f;
$color_pallate_10: #f4c20d;
$color_pallate_11: #6673fd;
$color_pallate_12: #3cba54;
$color_pallate_13: #2a2a2a;
$color_pallate_14: #4885ed;
$color_pallate_15: #ed0b4c; */


$color_pallate_1: $white;
$color_pallate_2: #3D5A80;
$color_pallate_3: $primary;
$color_pallate_4: #663259;
$color_pallate_5: #ff4961;
$color_pallate_6:  #fd7e14;
$color_pallate_7: #ffc107;
$color_pallate_8: #fff;
$color_pallate_9:  #20c997;
$color_pallate_10:  #17a2b8;
$color_pallate_11: #6c757d;
$color_pallate_12:#343a40;
$color_pallate_13: #2a2a2a;
$color_pallate_14: #4885ed;
$color_pallate_15: #4cb32b;

$text-color-1: $white;
$text-color-2: #6610f2;
$text-color-3: $primary;
$text-color-4: #dc3545;
$text-color-5: #dc3545;
$text-color-6: #fd7e14;
$text-color-7: #ffc107;
$text-color-8: #ff5ed2;
$text-color-9: #20c997;
$text-color-10: #17a2b8;
$text-color-11: #94618E;
$text-color-12: #343a40;
$text-color-13: #83418b;
$text-color-14: #4885ed;
$text-color-15: #4cb32b;

//utility variables
$radius: 0.5rem; 



